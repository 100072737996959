import React from "react"
import { Link } from "react-router-dom";

function Navbar () { 
    return (
        <>
            <nav className="w-full h-20 bg-white fixed top-0 border py-4 px-28 flex justify-between">
                <div className="flex gap-x-16">
                    <Link to="/">
                        <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="page-misc-error-light" width="100px" />
                    </Link>
                    <ul className="flex gap-x-10 hidden md:hidden lg:flex">
                        <li>Solutions</li>
                        <li>About Us</li>
                        <li>Projects</li>
                        <li>Blogs</li>
                    </ul>
                </div>
                <div className="flex gap-x-10 items-center hidden md:hidden lg:flex">
                    <button>Support</button>
                    <button className="py-[12px] px-6 bg-blue-500 rounded-lg text-white border">Contact Us</button>
                </div>
            </nav>
        </>
    )

}

export default Navbar