// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer-mob {
    border-top: 1px solid #E2E8F0;
}`, "",{"version":3,"sources":["webpack://./src/components/FooterComponent/style.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC","sourcesContent":["#footer-mob {\n    border-top: 1px solid #E2E8F0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
