// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/bgblogsample.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BlogCard .bg-blog {
    width: 100%;
    height: 240px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});  
    background-size: cover;
    border-radius: 12px;
    margin-bottom: 16px;
}
#BlogCard .categ_time {
    color: var(--primary-500, #3B82F6);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
#BlogCard .blog-title {
    color: var(--blue-gray-900, #0F172A);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; 
}
#BlogCard .description {
    color: var(--blue-gray-500, #64748B);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 64px;
}`, "",{"version":3,"sources":["webpack://./src/components/Blogs/BlogCardStyle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,yDAA2D;IAC3D,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,kCAAkC;IAClC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,oCAAoC;IACpC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,oCAAoC;IACpC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":["#BlogCard .bg-blog {\n    width: 100%;\n    height: 240px;\n    background-image: url(../../../public/img/bgblogsample.png);  \n    background-size: cover;\n    border-radius: 12px;\n    margin-bottom: 16px;\n}\n#BlogCard .categ_time {\n    color: var(--primary-500, #3B82F6);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 20px;\n}\n#BlogCard .blog-title {\n    color: var(--blue-gray-900, #0F172A);\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 32px; \n}\n#BlogCard .description {\n    color: var(--blue-gray-500, #64748B);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 24px;\n    margin-bottom: 64px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
