// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#projects .title-project{
    color: var(--gray-900, #101828) !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}
@media only screen and (max-width: 480px) {

    #projects .big-title {
        font-size: 24px;
    }
    #projects .title-project a{
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectComponent/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;;IAEI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":["#projects .title-project{\n    color: var(--gray-900, #101828) !important;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 32px;\n}\n@media only screen and (max-width: 480px) {\n\n    #projects .big-title {\n        font-size: 24px;\n    }\n    #projects .title-project a{\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
