import React from "react";
import "./style.css";

function ProjectDetailsComponent() {
    return(
        <>
            <div className="mx-custom-112 lg:block hidden">
                <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">Speeding Up Knowledge, PT. British Council Indonesia’s  Fiber Optic Makeover</h1>
            </div>
            <div className="mx-4 lg:hidden sm:block">
                <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">Speeding Up Knowledge, PT. British Council Indonesia’s  Fiber Optic Makeover</h1>
            </div>
            <div className="w-full img-hero lg:h-custom-516 h-custom-250"></div>
            <div className="lg:mx-custom-112 px-4">
                <div className="flex lg:flex-row flex-col-reverse">
                    <div className="basis-3/4 lg:py-24 sm:pt-12 lg:px-custom-104">
                        <h3 className="text-custom-32 font-semibold mb-6">Background</h3>
                        <p className="text-lg text-custom-paragraph">PT. British Council Indonesia, a prominent educational institution, recognizing the need for a future-proof solution, sought to modernize its network infrastructure to meet the increasing demand for high-speed internet connectivity. Recognizing the pivotal role of technology in facilitating educational initiatives, they collaborated with Nusa Network Prakarsa to explore the advanced fiber optic cable technology for their better service.</p>
                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Challenge</h3>
                        <p className="text-lg text-custom-paragraph">PT. British Council Indonesia faced critical issue to network infrastructure.
                        It was outdated and unable to support bandwidth requirements for giving their better cultural and educational services.<br/><br/>As an educational institution heavily reliant on digital resources and online platforms for its day-to-day operations, they faced significant concerns regarding potential network disruptions during the installation of fiber optic cables. The installation of fiber optic cables required specialized knowledge and skills that PT. British Council Indonesia might not possess.<br/><br/>As the demand grow bigger, they need the foremost changing through their network infrastructure.</p>
                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Solution</h3>

                        <p className="text-lg text-custom-paragraph">Nusa Network Prakarsa provided skilled technicians and engineers with extensive experience in fiber optic cable installations to oversee and execute the project seamlessly.We also conducted a detailed assessment of PT. British Council Indonesia’s existing infrastructure to develop a customized installation plan tailored to their specific needs and challenges.</p><br/><br/>

                        <p className="text-lg text-custom-paragraph">Nusa Network Prakarsa conducted a thorough site survey to map the building layout, assess existing infrastructure, and determine the optimal cable route. 
                        The installation of FiberOptic (FO) Dropwired 2 core cable represented a critical upgrade for client’s network infrastructure. However, integrating this new technology into the existing framework posed</p><br/><br/>

                        <p className="text-lg text-custom-paragraph">a challenge due to potential compatibility issues and the need for seamless integration with legacy system. Surveying the allocation paths in the shaft panel room and evaluating the condition of both the British Council’s server room and the provider presented logistical challenges. Conducting thorough inspections while minimizing disruption to ongoing operations demanded careful planning and execution.</p>

                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Conclusion</h3>
                        <p className="text-lg text-custom-paragraph">Through meticulous planning, effective stakeholder engagement, and professional execution, British Council Indonesia successfully navigated the challenges associated with upgrading its network infrastructure with the FO Dropwired 2 core cable installation. </p><br/><br/>

                        <p className="text-lg text-custom-paragraph">These advancements directly support strategic initiatives, enhance decision-making capabilities and empower PT. British Council Indonesia to remain competitive in the regional and international educational landscape. This successful project exemplifies PT. British Council Indonesia’s commitment to innovation and excellence in providing cutting-edge educational services to its stakeholders.</p>

                    </div>
                    <div className="basis-1/4 relative lg:py-24 py-12">
                        <div className="lg:w-72 w-full border bg-custom-card p-6 rounded-2xl">
                            <div className="flex flex-col mb-6">
                                <span className="text-base text-custom-paragraph">Industry</span>
                                <span className="text-xl font-semibold">Education</span>
                            </div>
                            <div className="flex flex-col mb-6">
                                <span className="text-base text-custom-paragraph">Timeline</span>
                                <span className="text-xl font-semibold">2023</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-base text-custom-paragraph">Solutions</span>
                                <span className="text-xl font-semibold">Professional Services</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='py-16'>
                    <h3 className="lg:text-4xl text-2xl font-bold mb-10">Read more our customer stories</h3>

                    <div className="flex lg:flex-row flex-col lg:space-x-4 lg:space-y-0 space-y-4 lg:mb-24">
                        <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard4.png'})`}}>
                            <div className="flex flex-col justify-between h-full">
                                <img className="w-36" src={process.env.PUBLIC_URL + '/img/blog/tereos.png'} />
                                <h1 className="lg:text-3xl text-base font-semibold text-white">Speeding Up Knowledge, PT. British Council Indonesia’s Fiber Optic Makeover</h1>
                            </div>
                        </div>
                        <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard3.png'})`}}>
                            <div className="flex flex-col justify-between h-full">
                                <img className="w-36" src={process.env.PUBLIC_URL + '/img/blog/kalbe.png'} />
                                <h1 className="lg:text-3xl text-base font-semibold text-white">Fortigate 200F: The Secret Ingredient to PT. Tereos FKS Indonesia’s Digital Success</h1>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProjectDetailsComponent