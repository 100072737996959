import FooterComponent from './FooterComponent/index'
import NavbarComponent from './NavbarComponent/index'
import Navbar from  './Layout/Navbar'
import ProjectComponent from './ProjectComponent/index'
import TestimoniComponent from './TestimoniComponent/index'
import CTAComponent from './CTAComponent/index'
import TechnologyPartnerComponent from './TechnologyPartnerComponent/index' 
import ServiceComponent from './ServiceComponent/index' 
// import NavbarDropdownComponent from './NavbarDropdownComponent/NavbarDropdownComponent' 
import ContactFormComponent from './ContactFormComponent/index' 
import ContactInformationComponent from './ContactInformationComponent/index' 
import ProjectDetailsComponent from './ProjectDetailsComponent/index' 
import ProjectDetails1 from './ProjectDetailsComponent/index1' 
import ProjectDetails2 from './ProjectDetailsComponent/index2' 
import ProjectDetails3 from './ProjectDetailsComponent/index3' 
import ProjectDetails4 from './ProjectDetailsComponent/index4' 

import AboutUsDetailsComponent from './AboutUsDetailsComponent/index'
import BlueHeaderComponent from './BlueHeaderComponent/index'
// import ProjectCard from './Projects/ProjectCard'
import BlogCard from './Blogs/BlogCard'
import BlogDetails from './Blogs/BlogDetails'
import Career from './Career/Career'
import CareerDetails from './Career/CareerDetails'



import DataCenterSolutions from './Solutions/DataCenterSolutions'
import SecuritySolutions from './Solutions/SecuritySolutions'
import IoTSolutions from './Solutions/IoTSolutions'
import NetworkSolutions from './Solutions/NetworkSolutions'
import CTASolutions from './Solutions/CTASolutions'
import CompanyList from './Solutions/CompanyList'
import GlipGlop from './Solutions/GlipGlop'
import SolutionDetails from './Solutions/SolutionDetails'

import ChainsawComponent from './Products/ChainsawComponent'
import ChainsawDetailComponent from './Products/ChainsawDetailComponent'

// error component
import Error404Component from './ErrorComponent/Error404'
import Button from './ui/Button'
import PaginationComponent from './PaginationComponent'
import SkeletonComponent from './SkeletonComponent'


export {
    FooterComponent, 
    NavbarComponent,
    Navbar,
    ProjectComponent, 
    TestimoniComponent, 
    CTAComponent, 
    TechnologyPartnerComponent, 
    ServiceComponent, 
    ContactFormComponent,
    ContactInformationComponent,
    ProjectDetailsComponent,
    ProjectDetails1,
    ProjectDetails2,
    ProjectDetails3,
    ProjectDetails4,
    AboutUsDetailsComponent,
    DataCenterSolutions,
    SecuritySolutions,
    IoTSolutions,
    NetworkSolutions,
    CTASolutions,
    CompanyList,
    GlipGlop,
    ChainsawComponent,
    ChainsawDetailComponent,
    Error404Component,
    BlueHeaderComponent,
    // ProjectCard,
    BlogCard,
    BlogDetails,
    Career,
    CareerDetails,
    SolutionDetails,
    // NavbarDropdownComponent
    Button,
    PaginationComponent,
    SkeletonComponent
}

