// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#blueHeader {
    padding-left: 112px;
    padding-right: 112px;
}
#blueHeader h1 {
    font-feature-settings: 'liga' off, 'clig' off, 'calt' off;
    font-family: Plus Jakarta Sans;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.92px;
    background: var(--Gradient-800---500, linear-gradient(45deg, #1E40AF 0%, #3B82F6 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
#blueHeader div.decor_ring {
    width: 1200px;
    height: 1200px;
    flex-shrink: 0;
    border-radius: 1200px;
    border: 30px solid #3B82F6;
    filter: blur(50px);
    position: absolute;
    right: -600px;
    top: -700px;
}`, "",{"version":3,"sources":["webpack://./src/components/BlueHeaderComponent/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,yDAAyD;IACzD,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,uFAAuF;IACvF,qBAAqB;IACrB,6BAA6B;IAC7B,oCAAoC;;AAExC;AACA;IACI,aAAa;IACb,cAAc;IACd,cAAc;IACd,qBAAqB;IACrB,0BAA0B;IAC1B,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf","sourcesContent":["#blueHeader {\n    padding-left: 112px;\n    padding-right: 112px;\n}\n#blueHeader h1 {\n    font-feature-settings: 'liga' off, 'clig' off, 'calt' off;\n    font-family: Plus Jakarta Sans;\n    font-size: 96px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    letter-spacing: -1.92px;\n    background: var(--Gradient-800---500, linear-gradient(45deg, #1E40AF 0%, #3B82F6 100%));\n    background-clip: text;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n\n}\n#blueHeader div.decor_ring {\n    width: 1200px;\n    height: 1200px;\n    flex-shrink: 0;\n    border-radius: 1200px;\n    border: 30px solid #3B82F6;\n    filter: blur(50px);\n    position: absolute;\n    right: -600px;\n    top: -700px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
