// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#technology-partner-company-list {
    margin-top: 64px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    

}
#technology-partner-company-list img {
    width: 218px;
    height: 64px;
    padding: 8px 0px;
    margin-bottom: 32px;
}
@media only screen and (max-width: 480px) {

    #technology-partner-mob .big-title {
        font-size: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/TechnologyPartnerComponent/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,WAAW;IACX,eAAe;IACf,8BAA8B;;;AAGlC;AACA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;AACA;;IAEI;QACI,eAAe;IACnB;AACJ","sourcesContent":["#technology-partner-company-list {\n    margin-top: 64px;\n    display: flex;\n    width: 100%;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    \n\n}\n#technology-partner-company-list img {\n    width: 218px;\n    height: 64px;\n    padding: 8px 0px;\n    margin-bottom: 32px;\n}\n@media only screen and (max-width: 480px) {\n\n    #technology-partner-mob .big-title {\n        font-size: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
