import "./style.css";

function CompanyList() {
    return (
        <>
            <div className="company-logo-list">
                <img src={process.env.PUBLIC_URL + '/img/company1.svg'} />
                <img src={process.env.PUBLIC_URL + '/img/company2.svg'} />
                <img src={process.env.PUBLIC_URL + '/img/company3.svg'} />
                <img src={process.env.PUBLIC_URL + '/img/company4.svg'} />
                <img src={process.env.PUBLIC_URL + '/img/company5.svg'} />
            </div>
        </>
    );
}

export default CompanyList;